import { render, staticRenderFns } from "./wzCont.vue?vue&type=template&id=1c2fcc48&scoped=true"
import script from "./wzCont.vue?vue&type=script&lang=js"
export * from "./wzCont.vue?vue&type=script&lang=js"
import style0 from "./wzCont.vue?vue&type=style&index=0&id=1c2fcc48&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c2fcc48",
  null
  
)

export default component.exports