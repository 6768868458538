<template>
  <div>
    <div class="bjffff"></div>
    <div class="content">
      <div class="contentBox">
        <div class="contHead">
          <div class="fbBox">
            <div class="phoimg" @click="yhClick">
              <img :src="$imgUrl + info.memberavatar" alt="" />
            </div>
            <div class="name" @click="yhClick">{{ info.membername }}</div>
          </div>
          <div class="lxBox">
            <!-- <div class="box">
              <div class="yuan">#</div>
              <div class="name">类型类型</div>
            </div> -->
            <div class="box hongb">
              <div class="yuanimg">
                <img src="../../assets/img/logo.png" alt="" srcset="" />
              </div>
              <div class="name">用户发布</div>
            </div>
          </div>
        </div>
        <div class="contHeadTxt">{{ info.name }}</div>
        <div class="contTxt" v-html="info.content"></div>
        <div class="imgs" v-if="info.resources">
          <div class="img" v-for="item in info.resources">
            <img :src="$imgUrl + item.path_name" alt="" srcset="" />
          </div>
        </div>
        <div class="funBox">
          <div class="boxs">
            <div class="box">
              <div class="iconfont icon-browse browse"></div>
              <div class="num">{{ info.click }}</div>
            </div>
            <div class="box">
              <div class="iconfont icon-message message"></div>
              <div class="num">{{ info.childrenlength }}</div>
            </div>
            <div class="box" style="cursor: pointer" @click="favoriteClick">
              <div
                class="iconfont praise"
                :class="
                  info.is_favorite ? 'icon-praise_fill on' : 'icon-praise'
                "
              ></div>
              <div class="num">{{ info.praise }}</div>
            </div>
            <div class="box">
              <div class="iconfont icon-send send"></div>
              <div class="num">分享</div>
              <div class="fxBox">
                <div class="lianjieBox dis-cent">
                  <div class="iconfont icon-lianjie lianjie"></div>
                </div>
                <div class="name" @click="lianjieClick()">复制链接</div>
              </div>
            </div>
          </div>
          <div class="time">{{ info.create_time }}</div>
        </div>
        <div class="hfBox">
          <div class="img">
            <img
              :src="member.avatar ? $imgUrl + member.avatar : phone"
              alt=""
            />
            <!-- <img src="../../assets/img/logo.png" alt="" srcset=""> -->
          </div>
          <div class="inpCont">
            <div class="inpBox">
              <input
                type="text"
                placeholder="终于等到你的回答啦~"
                v-model="seachName"
                @keyup.enter="searchClick(id)"
              />
              <!-- <div class="upImg">
                <div class="iconfont icon-picture"></div>
              </div> -->
            </div>
            <div class="anniu" @click="searchClick(id)">发布</div>
          </div>
        </div>
        <div class="contList">
          <div class="contLi" v-for="(item, index) in plList">
            <div class="contLiHead">
              <div class="img">
                <img
                  :src="item.memberavatar ? $imgUrl + item.memberavatar : phone"
                  alt=""
                />
              </div>
              <div class="name">{{ item.membername }}</div>
              <div class="time">{{ item.create_time }}</div>
            </div>
            <div class="contLiTxt">{{ item.content }}</div>
            <div class="contLiBox">
              <div
                class="iconfont icon-message message"
                @click="plClick(item.id, index)"
              ></div>
              <div
                class="iconfont like"
                :class="item.is_favorite ? 'icon-like_fill on' : 'icon-like'"
                @click="plfavoriteClick(item, index)"
              ></div>
            </div>
            <div class="contLiChe" v-if="item.children.length != 0">
              <div class="li" v-for="(chr, chi) in item.children">
                <div class="liHead">
                  <!-- <div class="name">回复人</div>
                  <div class="txt">回复</div> -->
                  <div class="name">{{ chr.membername }}</div>
                  <div class="time">{{ chr.create_time }}</div>
                </div>
                <div class="liTxt">{{ chr.content }}</div>
                <div class="liBox">
                  <div
                    class="iconfont icon-message message"
                    @click="plClick(item.id, index)"
                  ></div>
                  <div
                    class="iconfont like"
                    :class="chr.is_favorite ? 'icon-like_fill on' : 'icon-like'"
                    @click="ziplfavoriteClick(chr, index, chi)"
                  ></div>
                </div>
              </div>
            </div>
            <div class="inpBox" v-if="item.select">
              <input
                type="text"
                placeholder="请输入内容"
                v-model="ziseachName"
                @keyup.enter="zisearchClick(item.id, index)"
              />
            </div>
          </div>
          <!-- <div class="contLi">
            <div class="contLiHead">
              <div class="img">
                <img
                  src="https://img2.baidu.com/it/u=3571084029,1631856297&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1719334800&t=d14ab6fd9611631ad5c60a337d6bf267"
                  alt=""
                />
              </div>
              <div class="name">名字</div>
              <div class="time">2024-04-14 00:00:00</div>
            </div>
            <div class="contLiTxt">回复回复回复回复回复回复回复回复回复</div>
            <div class="contLiBox">
              <div
                class="iconfont icon-message message"
                @click="pinglunShow"
              ></div>
              <div class="iconfont icon-like like"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <Pinglun v-if="wzshow" @show="wenzhangShowJs" />
  </div>
</template>

<script>
import { number } from "echarts";
import Pinglun from "../component/pinglun.vue";

export default {
  components: {
    Pinglun,
  },
  name: "",
  data() {
    return {
      wzshow: false,
      seachName: "",
      info: {},
      plList: [],
      member: {},
      ziseachName: "",
      phone: require("../../assets/img/logo.png"),
    };
  },
  created() {
    this.id = this.$route.query.id;
    if (window.localStorage.getItem("member")) {
      this.member = JSON.parse(window.localStorage.getItem("member"));
    }
    this.get();
  },
  methods: {
    favoriteClick() {
      this.$post("/web/favorite_save", {
        genre: 1,
        id: Number(this.id),
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          if (res.data.data) {
            this.info.praise++;
          } else {
            this.info.praise--;
          }
          this.info.is_favorite = res.data.data;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    plfavoriteClick(item, i) {
      this.$post("/web/favorite_save", {
        genre: 1,
        id: Number(item.id),
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          this.plList[i].is_favorite = res.data.data;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    ziplfavoriteClick(item, i, chi) {
      this.$post("/web/favorite_save", {
        genre: 1,
        id: Number(item.id),
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          this.plList[i].children[chi].is_favorite = res.data.data;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    searchClick(id) {
      if (!this.seachName) {
        this.$message.warning("内容不能为空！");
      }
      let data = {};
      data.parent_id = Number(id);
      data.content = this.seachName;
      data = JSON.stringify(data);
      this.$post("/web/forum_save", {
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          console.log(this.plList);
          res.data.data.children = [];
          this.plList.unshift(res.data.data);
          this.seachName = "";
          this.$message.success("评论成功");
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    zisearchClick(id, i) {
      if (!this.ziseachName) {
        this.$message.warning("内容不能为空！");
      }
      let data = {};
      data.parent_id = Number(id);
      data.content = this.ziseachName;
      data = JSON.stringify(data);
      this.$post("/web/forum_save", {
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          this.plList[i].children.unshift(res.data.data);
          this.ziseachName = "";
          this.$message.success("评论成功");
          this.plNoneClick();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    plNoneClick() {
      for (let i = 0; i < this.plList.length; i++) {
        const e = this.plList[i];
        e.select = false;
      }
    },
    yhClick() {
      this.$router.push({
        path: "/userCont",
        query: {
          id: this.info.member_id,
        },
      });
    },
    get() {
      this.$get("/web/forum_get", {
        id: this.id,
      }).then((res) => {
        console.log(res.data);
        this.info = res.data;
        if (res.data.children) {
          for (let i = 0; i < res.data.children.length; i++) {
            const e = res.data.children[i];
            e.select = false;
            if (!e.children) {
              e.children = [];
            }
          }
          this.plList = res.data.children;
        }
      });
    },
    lianjieClick() {
      const textarea = document.createElement("textarea");
      textarea.value = this.info.name + " " + window.location.href;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message.success("复制成功");
    },
    plClick(id, i) {
      console.log(id);
      console.log(i);
      // for (let i = 0; i < this.plList.length; i++) {
      //   const e = this.plList[i];
      //   e.select = false;
      // }
      // this.plList[i].select = true;
      this.ziPlId = id;
      this.ziPlIndex = i;
      this.wzshow = true;
      this.$forceUpdate();
    },
    pinglunShow() {
      this.wzshow = true;
    },
    wenzhangShowJs(data) {
      this.wzshow = false;
      if (data) {
        let i = this.ziPlIndex;
        data.parent_id = Number(this.ziPlId);
        data = JSON.stringify(data);
        this.$post("/web/forum_save", {
          data: data,
        }).then((res) => {
          console.log(this.plList[i]);
          if (res.data.status == 1) {
            this.plList[i].children.unshift(res.data.data);
            this.ziseachName = "";
            this.$message.success("评论成功");
            this.plNoneClick();
          } else {
            this.$message.warning(res.data.msg);
          }
        });
      }
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.content {
  width: 1440px;
  margin: auto;
  background-color: #f5f4f4;
  box-sizing: border-box;

  .contentBox {
    width: 850px;
    background-color: #fff;
    box-sizing: border-box;
    height: calc(100vh - 55px);
    padding: 20px;
    border-bottom: 1px solid #ededed;
    margin: auto;
    overflow: auto;

    .contHead {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 13px;

      .fbBox {
        display: flex;
        align-items: center;
        flex: 1;
        .phoimg {
          width: 40px;
          height: 40px;
          cursor: pointer;

          img {
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .name {
          color: #505766;
          margin-left: 10px;
          cursor: pointer;
        }

        .txt {
          padding: 0 6px;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          margin-right: 8px;
          border: 1px solid #fb9900;
          color: #fb9900;
          border-radius: 2px;
          margin-left: 15px;
        }

        .txtlan {
          color: #7b8aff;
          border: 1px solid #7b8aff;
        }
      }

      .lxBox {
        display: flex;
        align-items: center;

        .box {
          padding: 0 12px;
          line-height: 28px;
          font-weight: 500;
          color: #333;
          text-align: center;
          font-size: 12px;
          border-radius: 14px;
          background: #eff9ff;
          margin-left: 16px;
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;

          .yuan {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            background: linear-gradient(to right, #00abee, #55c6f3);
            font-weight: 600;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .yuanimg {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            font-weight: 600;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .hongb {
          background-color: #ffefef;
        }
      }
    }

    .contHeadTxt {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .contTxt {
      line-height: 150%;
    }
    .imgs {
      overflow: hidden;
      .img {
        width: calc(25% - 11.25px);
        margin-right: 15px;
        padding-top: calc(25% - 11.25px);
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
          border-radius: 5px;
        }
      }
      .img:nth-child(2n) {
        margin-right: 0;
      }
    }

    .funBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #999;
      margin-top: 10px;
      border-bottom: 1px solid #ebebeb;
      padding-bottom: 10px;

      .boxs {
        display: flex;

        .box {
          display: flex;
          align-items: center;
          margin-right: 15px;
          position: relative;
          .browse {
            font-size: 18px;
          }

          .message {
            font-size: 16px;
          }

          .praise {
            font-size: 16px;
          }
          .on {
            color: rgb(235, 8, 27);
          }
          .send {
            font-size: 16px;
          }

          .num {
            position: relative;
            top: -1px;
            font-size: 12px;
            margin-left: 2px;
          }
          .fxBox {
            position: absolute;
            top: 0;
            right: -100px;
            box-shadow: 0 0 4px 2px #ebebeb;
            background-color: #fff;
            display: none;
            width: 105px;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            height: 40px;
            cursor: pointer;
            .lianjieBox {
              color: #fff;
              background-color: #f56709;
              border-radius: 50%;
              height: 20px;
              width: 20px;
              margin-right: 5px;
              .lianjie {
                font-size: 12px;
              }
            }
          }
        }
        .box:hover {
          .fxBox {
            display: flex;
          }
        }
      }
    }

    .hfBox {
      display: flex;
      align-items: center;
      height: 70px;
      border-bottom: 1px solid #ebebeb;
      margin: 0 15px;

      .img {
        border-radius: 50%;
        overflow: hidden;
        width: 40px;
        height: 40px;
      }

      .inpCont {
        height: 40px;
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        padding-left: 15px;

        .inpBox {
          width: calc(100% - 80px);
          border-radius: 5px;
          border: 1px solid #d6d6d6;
          height: 30px;
          position: relative;
          overflow: hidden;

          input {
            height: 100%;
            font-size: 12px;
            padding-left: 10px;
            width: 100%;
            box-sizing: border-box;
          }

          .upImg {
            position: absolute;
            top: 0px;
            right: 8px;

            .iconfont {
              font-size: 20px;
              color: #9c9c9c;
            }
          }
        }

        .anniu {
          width: 65px;
          height: 30px;
          background-color: #e23;
          color: #fff;
          line-height: 30px;
          text-align: center;
          margin-left: 15px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }

    .contList {
      padding: 0 15px;
      padding-top: 20px;

      .contLi {
        margin-bottom: 15px;
        .contLiHead {
          display: flex;
          align-items: center;

          .img {
            overflow: hidden;
            height: 35px;
            width: 35px;
            border-radius: 50%;
          }

          .name {
            margin-left: 10px;
          }

          .time {
            margin-left: 40px;
            color: #888;
          }
        }

        .contLiTxt {
          padding-left: 45px;
          font-size: 12px;
          color: #666;
          margin-top: 5px;
        }

        .contLiBox {
          padding-left: 45px;
          display: flex;
          margin-top: 5px;
          .iconfont {
            font-size: 16px;
            color: #999;
            margin-right: 40px;
            font-weight: 500;
            cursor: pointer;
          }
          .on {
            color: #e23;
          }
        }
        .inpBox {
          margin-left: 45px;
          height: 35px;
          border: 1px solid #ebebeb;
          border-radius: 5px;
          margin-top: 5px;
          overflow: hidden;
          input {
            width: 100%;
            height: 100%;
            padding-left: 10px;
            font-size: 12px;
          }
        }
        .contLiChe {
          margin-left: 45px;
          border-left: 2px solid #ff6647;
          padding-left: 15px;
          .li {
            padding: 10px 0;
            .liHead {
              display: flex;
              align-items: center;
              .name {
                color: #333;
              }
              .txt {
                margin: 0 6px;
                color: #999;
              }
              .time {
                margin-left: 40px;
                color: #999;
              }
            }
            .liTxt {
              line-height: 150%;
              margin-top: 10px;
              font-size: 13px;
              color: #888;
            }
            .liBox {
              display: flex;
              margin-top: 5px;
              .iconfont {
                font-size: 14px;
                color: #999;
                margin-right: 40px;
                font-weight: 500;
                cursor: pointer;
              }
              .on {
                color: #e23;
              }
            }
          }
        }
      }
    }
  }
}
</style>
